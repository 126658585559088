<script>
import firebase from 'firebase/app';
import moment  from "moment";
/**
 * Revenue Analytics component
 */
export default {
    data() {
        return {
            anoAtual : moment().year(),
            anoAnterior : moment().year() - 1,
            height:280,
            series: [
                {
                    name: moment().year(),
                    type: "column",
                    data: [10,20,30,40,50,60,70,80,90,100,110,120]
                },
                {
                    name: moment().year() - 1,
                    type: "line",
                    data: [10,20,30,40,50,60,70,80,90,100,110,120]
                }
            ],
            chartOptions: {
                chart: {
                    height: 280,
                    type: "line",
                    toolbar: {
                        show: false
                    }
                },
                stroke: {
                    width: [0, 3],
                    curve: "smooth"
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: "20%"
                    }
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false
                },
                colors: ["#5664d2", "#1cbb8c"],
                labels: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec"
                ]
            }
        };
    },
    mounted() {
        firebase.firestore()
            .collection('/dashboards/vendas/mes_a_mes')
            .orderBy('data', 'desc')
            .limit(24).get().then(snapshot => {
            console.log(snapshot.docs);
            var dados = snapshot.docs.map(doc => doc.data());
            console.log(dados)
            this.series[0].data = [];
            this.series[1].data = [];
            for (var x=0;x<12;x++) {
                let data = moment().month(x).format('YYYY-MM');
                let dataAnterior = moment().month(x).subtract(1, 'year').format('YYYY-MM');
                let dataDado = dados.find(dado => dado.data == data);
                let dataAnteriorDado = dados.find(dado => dado.data == dataAnterior);

                if(dataDado==undefined){
                    dataDado = {
                        data: data,
                        valor: 0,
                        qtd: 0
                    }
                }
                if(dataAnteriorDado==undefined){
                    dataAnteriorDado = {
                        data: data,
                        valor: 0,
                        qtd: 0
                    }
                }

                this.series[0].data.push(dataDado.valor)
                this.series[1].data.push(dataAnteriorDado.valor)

            }

            this.height = 281;
            console.log(this.series)
        }).catch(error => {
            console.log(error);
        })
    }
};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <h4 class="card-title mb-4">Evolução ano {{ anoAtual }}</h4>
            <div>
                <div id="line-column-chart" class="apex-charts" dir="ltr"></div>
                <apexchart
                    class="apex-charts"
                    :height="height"
                    dir="ltr"
                    :series="series"
                    :options="chartOptions"
                ></apexchart>
            </div>
        </div>


    </div>
</template>