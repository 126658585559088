<script>
/**
 * Widget Component
 */
import firebase from 'firebase/app';

export default {
    data() {
        return {
            statData: [
                {
                    title: "Assinantes pagos",
                    icon: "ri-stack-line",
                    value: "Carregando...",
                    subvalue: "",
                    type: true
                },
                {
                    title: "Vendas",
                    icon: "ri-store-2-line",
                    value: "Carregando...",
                    subvalue: "",
                    type: true
                }
            ]
        };
    },
    mounted() {
        firebase.firestore()
            .collection('/dashboards/clientes/full_ate_ativos')
            .orderBy('data_leitura_mes', 'desc')
            .limit(2).get().then(snapshot => {
                try {
                    this.statData[0].value = snapshot.docs[0].data().total;
                } catch (e) {
                    this.statData[0].value = "Erro...";
                }
                try {
                    var mesPassado = snapshot.docs[1].data().total;
                    var mesAtual = parseInt(snapshot.docs[0].data().total);
                    var total = (((mesPassado - mesAtual) / mesPassado) * 100).toFixed(2);
                    if (mesPassado > mesAtual) {
                        this.statData[0].type = false
                        this.statData[0].subvalue = (total * -1) + "%";
                    } else {
                        total = (((mesAtual - mesPassado) / mesAtual) * 100).toFixed(2);
                        this.statData[0].type = true
                        this.statData[0].subvalue = total + "%";
                    }
                } catch (e) {
                    this.statData[0].subvalue = "sem dados";
                }
            }
        ).catch(err => {
            console.log('Error getting documents', err);
            this.statData.forEach(item => {
                item.value = "Erro...";
            });
        });

        firebase.firestore()
            .collection('/dashboards/vendas/mes_a_mes')
            .orderBy('data', 'desc')
            .limit(2).get().then(snapshot => {

                try {
                    this.statData[1].value = "R$ " + snapshot.docs[0].data().valor.toFixed(2).toString().replace('.',',');
                } catch (e) {
                    this.statData[1].value = "Erro...";
                }
                try {
                    var mesPassado = snapshot.docs[1].data().valor;
                    var mesAtual = parseInt(snapshot.docs[0].data().valor);
                    var total = (((mesPassado - mesAtual) / mesPassado) * 100).toFixed(2);
                    if (mesPassado > mesAtual) {
                        this.statData[1].type = false
                        this.statData[1].subvalue = (total * -1) + "%";
                    } else {
                        total = (((mesAtual - mesPassado) / mesAtual) * 100).toFixed(2);
                        this.statData[1].type = true
                        this.statData[1].subvalue = total + "%";
                    }
                } catch (e) {
                    this.statData[1].subvalue = "sem dados";
                }
            }
        ).catch(err => {
            console.log('Error getting documents', err);
            this.statData.forEach(item => {
                item.value = "Erro...";
            });
        });
    }
};
</script>

<template>
    <div class="row">
        <div class="col-md-6" v-for="(data, index) in statData" :key="index">
            <div class="card">
                <div class="card-body">
                    <div class="media">
                        <div class="media-body overflow-hidden">
                            <p class="text-truncate font-size-14 mb-2">{{ data.title }}</p>
                            <h4 class="mb-0">{{ data.value }}</h4>
                        </div>
                        <div class="text-primary">
                            <i :class="`${data.icon} font-size-24`"></i>
                        </div>
                    </div>
                </div>

                <div class="card-body border-top py-3">
                    <div class="text-truncate">
            <span v-if="data.type" class="badge badge-soft-success font-size-11" style="margin-right: 10px">
              <i class="mdi mdi-menu-up"></i>
              {{ data.subvalue }}
            </span>

                        <span v-if="!data.type" class="badge badge-soft-danger font-size-11">
              <i class="mdi mdi-menu-up"></i>
              {{ data.subvalue }}
            </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end row -->
</template>