<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import Stat from "./widget";
import RevenueAnalytics from "./revenue";
import Transaction from './transaction';
import {
    mixedChart,
} from "./../charts/data-apex";
import firebase from "firebase";

/**
 * Dashboard component
 */
export default {
    components: {
        Layout,
        PageHeader,
        Stat,
        RevenueAnalytics,
        Transaction
    },
    data() {
        return {
            barChart: {
                height: 120,
                series: [{
                    data: [100 ]
                }],
                chartOptions: {
                    chart: {
                        toolbar: {
                            show: false,
                        }
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    colors: ['#34c38f'],
                    grid: {
                        borderColor: '#f1f1f1',
                    },
                    xaxis: {
                        categories: [ 'Carregando...' ],
                    }
                }
            },
            mixedChart: mixedChart,
            title: "Painel",
            items: [
                {
                    text: "Home"
                },
                {
                    text: "Painel",
                    active: true
                }
            ]
        };
    },
    mounted() {
        firebase.firestore()
            .collection('/dashboards/assinaturas/full_ate_ativos')
            .orderBy('data_leitura_mes', 'desc')
            .limit(1).get().then(snapshot => {

            let planos = snapshot.docs[0].data().planos;

            this.barChart.series[0].data = [];
            this.barChart.chartOptions.xaxis.categories = [];
            Object.keys(planos).forEach(key => {
                var item = planos[key];
                this.barChart.series[0].data.push(parseInt(item.assinaturasAtivas));
                this.barChart.chartOptions.xaxis.categories.push(item.name);
            });

            this.barChart.height=121;
            var temp = this.barChart;
            this.barChart = null;
            this.barChart = temp;

            console.log(planos,this.barChart);

        }).catch(err => {
            console.log('Error getting documents', err);

        });
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>


        <div class="row">
            <div class="col-8">
                <div class="row">
                    <div class="col-xl-8">
                        <Stat/>
                    </div>
                    <div class="col-xl-4">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title mb-4">Planos venda</h4>
                                <!-- Bar Chart -->
                                <apexchart
                                    style="margin-top:-40px; margin-bottom:-14px;"
                                    class="apex-charts"
                                    :height="barChart.height"
                                    type="bar"
                                    dir="ltr"
                                    :series="barChart.series"
                                    :options="barChart.chartOptions"
                                ></apexchart>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <RevenueAnalytics/>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">

                    </div>
                </div>
            </div>
            <div class="col-4">
                <h3>Pesquisa</h3>
                <Transaction/>
            </div>
        </div>

    </Layout>
</template>