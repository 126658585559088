<script>

import firebase from 'firebase/app';
import moment from "moment";
import algoliasearch from 'algoliasearch';

/**
 * Transactions component
 */
export default {
    data() {
        return {
            is_loading: false,
            url_vindi: process.env.VUE_APP_DEFAULT_VINDI || 'https://sandbox-app.vindi.com.br/',
            config_algoliasearch: process.env.VUE_APP_DEFAULT_ALGOLIASEARCH,
            config_algoliasearch_key: process.env.VUE_APP_DEFAULT_ALGOLIASEARCH_KEY,
            transactionData: [],
            busca: '',
            totalRows: 1,
            currentPage: 1,
            perPage: 6,
            pageOptions: [5, 10, 25, 50],
            filter: null,
            filterOn: [],
            sortBy: "orderid",
            sortDesc: false,
            fields: [
                {key: "orderid", sortable: true, label: "Order ID"},
                {key: "date", sortable: true},
                {key: "billingname", sortable: true, label: "Billing Name"},
                {key: "link", sortable: true, label: "Vindi"},
                // { key: "total", sortable: true, label: "Total" },
                // { key: "paymentstatus", sortable: true, label: "Payment Status" },
                // { key: "action" }
            ]
        };
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.transactionData.length;
        }
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.transactionData.length;


        this.listagem()
    },
    methods: {
        buscaSet() {
            this.listagem()
        },
        listagem() {
            this.is_loading = true;
            this.transactionData = [];
            let consulta = firebase.firestore().collection('cobrancas');
            if (this.busca && this.busca.length > 0) {
                const client = algoliasearch(this.config_algoliasearch, this.config_algoliasearch_key);
                const index = client.initIndex('empresas');
                index.search(this.busca).then(response => {
                    console.log('response', response);
                    let ids = response.hits.map(item => item.objectID)
                    console.log('ids', ids);
                    consulta = consulta.where('customer.code', 'in', ids);
                    this.listagemLoad(consulta);
                }).catch(error => {
                    this.is_loading = false;
                    console.log(error);
                });
            } else {
                this.listagemLoad(consulta);
            }
        },
        listagemLoad(consulta) {
            console.log('listagemLoad', consulta)
            consulta.where('status', '==', 'paid')
                .orderBy('created_at', 'desc')
                .limit(50).get().then(snapshot => {
                snapshot.docs.forEach(doc => {
                    console.log('final')
                    this.is_loading = false;
                    let item = doc.data();

                    var billingname = "";

                    try{
                        billingname = item.last_transaction.payment_profile.holder_name;
                    }catch (e) {
                        console.log('erro', e)
                    }

                    this.transactionData.push({
                        orderid: item.id,
                        date: moment(item.created_at, moment.ISO_8601).format('DD/MM/YYYY'),
                        billingname: billingname,
                        total: "R$ " + item.amount,
                        paymentstatus: "Pago",
                        link: this.url_vindi + "admin/charges/" + item.id,
                    })
                });

            }).catch(error => {
                console.log('error', error)
                this.is_loading = false;
                console.log(error);
            }).finally(() => {
                console.log('finally')
                this.is_loading = false;
            });
        },
        /**
         * Search the table data with search input
         */
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        }
    }
};
</script>

<template>

    <div class="card">


        <div class="card-body">
            <b-input @change="buscaSet" v-model="busca" placeholder="Busca..."></b-input>
            <br>
            <h4 class="card-title mb-4">Últimos pagamentos</h4>

            <div class="table-responsive">
                <h4 v-if="is_loading"> Carregando...</h4>
                <b-table
                    :items="transactionData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                >
                    <template v-slot:cell(paymentstatus)="row">
                        <div
                            class="badge font-size-12"
                            :class="{'badge-soft-danger': `${row.value}` === 'Chargeback',
              'badge-soft-success': `${row.value}` === 'Paid',
              'badge-soft-warning': `${row.value}` === 'Unpaid'}"
                        >{{ row.value }}
                        </div>
                    </template>

                    <template v-slot:cell(link)="row">
                        <a style="margin:5px 10px" class="btn  btn-primary" :href="row.value" target="_blank"><i class="dripicons-link"></i> vindi</a>
                    </template>
                    <template v-slot:cell(action)>
                        <a
                            href="javascript:void(0);"
                            class="mr-3 text-primary"
                            v-b-tooltip.hover
                            data-toggle="tooltip"
                            title="Edit"
                        >
                            <i class="mdi mdi-pencil font-size-18"></i>
                        </a>
                        <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete">
                            <i class="mdi mdi-trash-can font-size-18"></i>
                        </a>
                    </template>
                </b-table>
            </div>
            <div class="row">
                <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">
                            <!-- pagination -->
                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>